import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const TransferyPage = (props: Props) => {
  return (
    <Layout noBottomPadding>
      <SEO title="Transfery" />
      <h1>Transfery</h1>
      <p className="mb-4 text-justify">
        Alternativou k textilní etiketě může být nažehlovací transfer. Každá
        textilní etiketa má nepříjemnou vlastnost - ve výrobku „je navíc“ -
        překáží, při styku s pokožkou škrábe. Tuto nepříjemnost dokáže transfer
        zcela odstranit. Transfer je sice natisknut na přenosové plastové folii,
        ale ta slouží pouze jako dočasný nosič natištěného motivu. Při zažehlení
        se na textilní výrobek přenesou pouze vytištěné motivy, přenosová folie
        se odstraní. Výsledkem je tisk přímo na textilní materiál. Tisk zcela
        splyne s potiskovaným materiálem. Pokud jsou natištěna drobná loga a
        nápisy, zachovává si textilní materiál i původní elastičnost. Pouze u
        větších souvislých tištěných ploch se tiskem materiál zpevní a elastič-
        nost se sníží. Takto nažehlený tisk má výbornou trvanlivost, nebledne,
        nespírá se. Výhodou metody je neomezená barevnost, včetně tisku
        fotografií a obrázků. Metoda je plně digitální, nevznikají žádné náklady
        na přípravu tisku (štočky, tiskové formy, síta).
      </p>
      <p>Na všechny materiály máme certifikát OEKO-TEX.</p>
      <div className="justify-center flex">
        <StaticImage src="../images/transfery/transfery.png" alt="Transfery" />
      </div>
      <div className="justify-center flex mt-5 mb-5"></div>
      <div className="justify-center flex my-5 ">
        <table>
          <tr>
            <td className="font-bold pr-10">Minimální množství</td>
            <td>Velikost série bez omezení, není minimální množství</td>
          </tr>

          <tr>
            <td className="font-bold pr-10">Barvy</td>
            <td>
              Barva tisku libovolná v barevném prostoru CMYK (mimo metalických a
              neonových odstínů)
            </td>
          </tr>

          <tr>
            <td className="font-bold pr-10">Rozměry</td>
            <td>Libovolná do 600 mm</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Cena</td>
            <td>
              Cena je závislá na velikosti potisku a objednaném množství,
              barevnost a změny motivu nemají na cenu vliv.
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Dodací lhůta</td>
            <td>Cca 1 týden od potvrzení objednávky</td>
          </tr>
        </table>
      </div>
      <p>
        Poptávky a jakékoli dotazy či připomínky ohledně potisků zasílejte na:{" "}
        <a className="font-bold" href="mailto:tisk@etka.cz">
          tisk@etka.cz
        </a>
      </p>
      <div>
        <StaticImage
          src="../images/transfery/transfery-dole.png"
          alt="Transfery"
        />
      </div>
    </Layout>
  );
};

export default TransferyPage;
